const DateTime = require('luxon').DateTime;

module.exports = function (event, startDate, endDate) {
  let start = DateTime.fromISO(startDate);
  let end = DateTime.fromISO(endDate);

  let eventStart = DateTime.fromISO(event.startDate);
  let eventEnd = event.endDate ? DateTime.fromISO(event.endDate) : eventStart;

  if (eventStart > end) return 0;
  if (eventEnd < start) return 0;

  let days = 0;
  let current = eventStart;
  if (current < start) current = start;
  if (eventEnd > end) eventEnd = end;
  while (current <= eventEnd) {
    if (event.weekDayExceptions && event.weekDayExceptions.indexOf(current.weekday) >= 0) {
      // do not increment
    } else if (event.entries) { // new event format
      let date = current.toISODate();
      let entry = event.entries.find(e => e.date === date);
      if (entry) {
        days = days + entry.day;
      }
    } else if (event.entry) { // old event format
      days = days + event.entry.day;
    }
    current = current.plus({day: 1});
  }

  return days;
};