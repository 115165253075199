const DateTime = require('luxon').DateTime;
const Interval = require('luxon').Interval;
const computeSumsByIcon = require('../common/computeSumsByIcon');
const computeVacationsForEvents = require('../events/computeVacationsForEvents');
const vacationsForEvents = require('../events/vacationsForEvents');
const flatMap = require('../utils/flatMap');

module.exports = function (query, right, sheets, events) {
  let startDate = DateTime.fromISO(query.weekStart + '-1');
  let endDate = DateTime.fromISO(query.weekEnd + '-1').endOf('week');
  let planStart = DateTime.fromISO(query.weekEnd + '-1').endOf('week');
  let planEnd = DateTime.fromISO(query.rightEnd + '-1').endOf('week');
  if (planEnd.weekYear > endDate.weekYear) {
    planEnd = planEnd.minus({week: 1});
  }
  let details = query.details;

  let selection = Interval.fromDateTimes(startDate, endDate);

  let sheetsSelected = sheets.filter(sheet => {
    return selection.contains(DateTime.fromISO(sheet.startDate)) && selection.contains(DateTime.fromISO(sheet.endDate));
  });

  let accepted = sheetsSelected.filter(sheet => sheet.accepted);

  let effStartDate = sheetsSelected.length > 0 ? DateTime.min(...sheetsSelected.map(sheet => DateTime.fromISO(sheet.startDate))) : null;
  let effEndDate = sheetsSelected.length > 0 ? DateTime.max(...sheetsSelected.map(sheet => DateTime.fromISO(sheet.endDate))) : null;
  let summaries = flatMap(sheetsSelected, s => s.summaries ? s.summaries.filter(s => s.name === 'vacation') : []);
  let summariesSums = computeSumsByIcon(summaries);

  let total = right && right.vacations || 0;
  let vacation = summariesSums.find(s => s.name === 'vacation');
  let used = vacation ? vacation.day : 0;
  let planned = computeVacationsForEvents(events, planStart.toISODate(), planEnd.toISODate());
  let remaining = total - used - planned;

  let entries = [];
  if (details) {
    entries = flatMap(sheetsSelected, (sheet) =>
      flatMap(sheet.days, (day) => day.entries.map(e => Object.assign({}, e, {sheetId: sheet._id})))
    ).filter(entry => entry.op === 'vacation');
  }

  let plannedEvents = [];
  if (details) {
    plannedEvents = vacationsForEvents(events, planStart.toISODate(), planEnd.toISODate())
      .filter(entry => entry.vacations > 0);
  }

  return {
    startWeek: startDate.toISODate(),
    endWeek: endDate.toISODate(),

    planStart: planStart.toISODate(),
    planEnd: planEnd.toISODate(),

    startDate: effStartDate ? effStartDate.toISODate() : null,
    endDate: effEndDate ? effEndDate.toISODate() : null,

    sheetsCount: sheetsSelected.length,
    acceptedCount: accepted.length,

    entries,
    events: plannedEvents,

    right: right,
    used: {day: used},
    summaries: summariesSums,
    planned: {day: planned},
    remaining: {day: remaining}
  };
};
